import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { adminMoversAllRouting } from '@app/admin/constants/routing.constants';
import { realEstateAgentLoginRouting } from '@app/authentication/constants/routing.constants';
import { eotsAbsoluteRoute } from '@app/eots/constants/eots.constants';
import { iotsAbsoluteRoute } from '@app/iots/constants/iots.constants';
import { NavigationSandbox, ObjectUtils } from '@smooved/core';
import * as partnerRouting from '../../partner/constants/routing.constants';
import { Navigation } from '../enums/navigation.enum';

@Injectable({
    providedIn: 'root',
})
export class AppNavigationSandbox extends NavigationSandbox {
    constructor(
        protected router: Router,
        protected readonly route: ActivatedRoute,
        protected readonly location: Location
    ) {
        super(router, route, location);
    }

    public static getEotsAbsoluteNavigationUrl(navigation: string[]): string {
        return [eotsAbsoluteRoute, ...navigation].join('/');
    }

    public static getIotsAbsoluteNavigationUrl(navigation: string[]): string {
        return [iotsAbsoluteRoute, ...navigation].join('/');
    }

    public static getRouteParam(route: ActivatedRoute, key: string): string {
        return route.snapshot.params[key];
    }

    public static getRouteQueryParam(route: ActivatedRoute, key: string): string {
        return route.snapshot.queryParams[key];
    }

    public static getAbsolute(routing: string[]): string {
        return `/${routing.join('/')}`;
    }

    public getCurrentPath(): string {
        return this.router.url;
    }

    public goToHome(): Promise<boolean> {
        return this.router.navigateByUrl(`/`);
    }

    public goToStart(queryParams?: Params): Promise<boolean> {
        return this.router.navigate([`/`], { queryParams });
    }

    public goToStartRegisterMove(): Promise<boolean> {
        return this.router.navigateByUrl(`/${Navigation.StartRegisterMove}`);
    }

    public goToApp(refresh: boolean = false): Promise<boolean | void> {
        if (refresh) {
            return new Promise<boolean | void>((resolve) => {
                window.location.href = `${window.location.origin}/`;
                resolve();
            });
        } else {
            return this.router.navigateByUrl(`/`);
        }
    }

    public goToTelecomFlow(): Promise<boolean> {
        return this.router.navigateByUrl(`/${Navigation.Telecom}`);
    }

    public goToInsurancesFlow(): Promise<boolean> {
        return this.router.navigate([Navigation.Insurances]);
    }

    public goToLogin(): Promise<boolean> {
        return this.router.navigateByUrl(`/${Navigation.Login}`);
    }

    public goToRealEstateAgentLogin(redirectUrl?: string): Promise<boolean> {
        const extras = { queryParams: { redirectUrl } };
        ObjectUtils.removeEmpty(extras);
        return this.router.navigate(realEstateAgentLoginRouting, extras);
    }

    public goToRealEstateAgentRegister(): Promise<boolean> {
        return this.router.navigateByUrl(`/${Navigation.RealEstateAgentRegister}`);
    }

    public goToRealEstateAgentStart(): Promise<boolean> {
        return this.router.navigateByUrl(`/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentStart}`);
    }

    public goToTelecomInterest(): Promise<boolean> {
        return this.router.navigate(['/', Navigation.OTS, Navigation.Tots, Navigation.TelecomInterested]);
    }

    public goToPartnerAgentHome(): Promise<boolean> {
        return this.router.navigate(partnerRouting.baseRouting);
    }

    public goToPartnerAgentLogin(): Promise<boolean> {
        return this.router.navigate(partnerRouting.loginRouting);
    }

    public goToThanks(showConfirmText?: boolean): Promise<boolean> {
        if (showConfirmText) {
            return this.router.navigate([`/${Navigation.Thanks}`], {
                queryParams: {
                    showConfirmText: true,
                },
            });
        } else {
            return this.router.navigate([`/${Navigation.Thanks}`]);
        }
    }

    public goToOrderConfirmation(): Promise<boolean> {
        return this.router.navigate([`/${Navigation.App}/${Navigation.OrderConfirmation}`]);
    }

    public goToOrderConfirmationSingleGo(): Promise<boolean> {
        return this.router.navigate([`/${Navigation.App}/${Navigation.OrderConfirmationSingleGo}`]);
    }

    public goToMoveSaved(): Promise<boolean> {
        return this.router.navigate([`/${Navigation.MoveSaved}`]);
    }

    public goToRealEstateDashboard(id?: string, queryParams?: Params): Promise<boolean> {
        return this.router.navigate([`/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentDashboard}${id ? `/${id}` : ''}`], {
            queryParams,
        });
    }

    public goToAdminDashboard(): Promise<boolean> {
        return this.router.navigate(adminMoversAllRouting);
    }

    public goToEnergyFlow(): Promise<boolean> {
        return this.router.navigateByUrl(`/${Navigation.Energy}`);
    }

    public goToEotsFlow(): Promise<boolean> {
        return this.router.navigate([eotsAbsoluteRoute]);
    }

    public goToEotsMeterReadings(queryParams?: Params): Promise<boolean> {
        return this.router.navigate(['/', Navigation.OTS, Navigation.EOTS, Navigation.MeterReadings], { queryParams });
    }

    public goToOtsSuccess(): Promise<boolean> {
        return this.router.navigate(['/', Navigation.OTS, Navigation.Success]);
    }

    public isAdminRoute(): boolean {
        return this.router.url.includes(`/${Navigation.Admin}`);
    }

    public isEotsRoute(): boolean {
        return this.router.url.includes(Navigation.EOTS);
    }

    public getCreateLeaverRoute(): string {
        return ['/', Navigation.OTS, Navigation.LeaverDetails].join('/');
    }
}
